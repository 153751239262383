.background-block {
  background-color: $color;
}

.block-title {
  margin: 0 0 1.5em;
}
.block-title__title {
  margin: 0 0 0.5em;
  margin-top: 1.5em;
}
.block-title__subtitle {
  margin-bottom: 1em;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
}

.utfb-title {
  margin: 0 0 0.6em;
  font-size: 31px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
}
.utfb-subtitle {
  margin: 0 0 1.2em;
  font-size: 18px;
  font-weight: 400;
  max-width: 270px;
  color: #fff;
}

.un-take-form-box {
  form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
  }
  input {
    padding: 10px 10px;
    padding-left: 40px;
    background: transparent;
    width: 100%;
    border: none;
    color: #fff;
    outline: none;
    border-bottom: 1px solid #fff;
    &::placeholder {
      color: #fff;
    }
  }
  label {
    display: inline-block;
    position: relative;
    margin: 0 0 1.2em;
  }
  label i {
    position: absolute;
    left: 18px;
    top: 50%;
    color: #fff;
    -webkit-transform: translate3d(-50%, -50%, 0);
    -moz-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
  }
  .button {
    margin-top: 40px;
    width: 200px;
    border: none;
    color: #252525;
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
    padding: 15px 30px;
    border-radius: 30px;
    background: #e10447;
    color: #fff;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
      opacity: 0.9;
    }
  }
}

.block-title,
.university_title h2 {
  // margin-top: 2rem;
  font-size: 2.5rem;
  @media screen and (max-width: 550px) {
    font-size: 1.5rem;
  }
  font-weight: 700;
}

.flex-container {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.university_about__img,
.university_about__text {
  width: 100%;
  max-width: 640px;
}

.flex-item-video {
  max-width: 640px;
  min-width: 280px;
  width: 100%;
  min-height: 320px;
  flex: 1;
}
.flex-item-text {
  width: 100%;
  max-width: 640px;
  padding: 0 10px;
}

.history {
  p {
    text-indent: 1rem;
  }
}

.un_nav_box {
  // display: none;
  @media screen and (max-width: 1720px) {
    display: none;
  }
  position: fixed;
  right: 0.3%;
  top: 30%;
  background-color: $color;
  color: #fff;
  z-index: 100;
  box-shadow: 0 0 13px 7px #00000017;
}
.un_nav_box ul {
  padding: 1.3em 0 1.3em 1.7em;
  margin: 0;
}
.un_nav_box ul li {
  margin: 0;
  list-style-type: none;
}
.un_nav_box ul a {
  padding: 0.5em 0;
  border-bottom: 1px solid #72b5f5;
  padding-right: 1.2em;
  display: block;
  color: #fff;
  font-size: 14px;
  position: relative;
  opacity: 0.85;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}
.un_nav_box ul a:before {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  background-color: #e10447;
  position: absolute;
  left: -18px;
  top: 14px;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}
.un_nav_box ul li:hover a:before,
ul li.active a:before {
  opacity: 1;
}

.mymagicoverbox {
  display: block;
  position: fixed;
  
  background-color: #e10447;

  border: none;

  font-size: 36px;
  cursor: pointer;
  font-weight: 600;
  // padding: 15px 30px;
  padding: 5px 22px;
  // border-radius: 30px;
  border-radius: 50%;

  color: #fff;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  // border-radius: 50%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // font-size: 2rem;
  // width: 70px;
  // height: 70px;
  // animation-duration: 2s;
  // animation-name: slidein;
  // animation-iteration-count: infinite;
  i {
    color: #fff;
  }
  &:hover {
    color: #fff;
    opacity: 0.9;
    background-color: #dd4170;
  }
  /* отцентровать кнопку "Узнать подробности" для мобильных устройств */
  @media screen and (max-device-width:768px){
    top: 5px;
    right: 5px;
  //   left: 50%;
  //   transform: translate(-50%, 0%);
  //   padding: 3px 10px;
  //   max-width: 220px;
  }

  /* Положение справа внизу кнопки "Узнать подробности" для всех остальных устройств */
  @media screen and (min-width:769px){
    bottom: 30px;
    right: 30px;
  }

}

@keyframes slidein {
  from {
    // width: 72px;
    // height: 72px;
    transform: scale(1.2);
    // font-size: 2.2rem;
    // transform: rotate(-10deg);
  }
  50% {
    // width: 70px;
    // height: 70px;
    transform: scale(1);
    // font-size: 2rem;
    // transform: rotate(0deg);
  }
  to {
    // width: 72px;
    // height: 72px;
    transform: scale(1.2);
    // font-size: 2.2rem;
    // transform: rotate(-10deg);
  }
}

.un-take-form-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  .form-ajax {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    // align-items: center;
  }
  .button {
    margin-top: 10px;
  }
}

.red_link {
  color: #e10447;
  &:visited {
    color: #e10447;
  }
  &:hover {
    text-decoration: underline;
  }
}

.info-block {
  display: flex;
  flex-wrap: wrap;
  &__content {
    padding-left: 25px;
    .btn {
      margin-top: 30px;
    }
  }
}

.white-link-decor {
  color: #fff;
  text-decoration: underline;
  &:visited {
    color: #fff;
  }
  &:hover {
    text-decoration: none;
  }
}

.info2 {
  @media screen and (max-width: 360px) {
    display: none !important;
  }
  p {
    margin: 0;
  }
}

.social_icon_link {
  color: #fff;
  &:hover {
    color: #fff;
  }
  &:visited {
    color: #fff;
  }
}

.social_networks {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.custom_padding {
  padding-bottom: 2rem;
}


.phone-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}