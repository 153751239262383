@import "~bootstrap/scss/bootstrap";
$color: #2A3DAD;
$color2: $color;
@import "new";
@import "faculties";
@import "ege_calculator";
@import "cookies";

body {
  font-size: 16px;
  position: relative;
  font-family: "Montserrat";
}
a,
a:visited {
  color: $color;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.header {
  height: 400px;
  position: relative;
  background: url('../images/background.jpg') center top no-repeat;
  background-size: cover;
  h1 {
    @media screen and (min-width: 900px) {
      font-size: 54px;
    }
    font-weight: 700;
  }
  p {
    color: #fff;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
  }
}
.box {
  width: 100%;
  margin: 0 auto;
  max-width: 1280px;
  // height: 100%;
  // padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.how-proceed {
  padding: 14px 12px;
  border: none;
  // padding-left: 16px;
  display: inline-block;
  background: #fff;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  span {
    color: #252525;
    position: relative;
    transition: 0.45s;
  }
  &:hover {
    span {
      color: #fff;
    }
  }
  &:hover:before {
    width: 100%;
  }
  &:before {
    content: "";
    display: block;
    width: 4px;
    height: 100%;
    background: $color2;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: 0.45s;
    -o-transition: 0.45s;
    -moz-transition: 0.45s;
    transition: 0.45s;
  }
}

.custom-tab {
  margin: 0 0 45px;
  font-size: 18px;
  margin-right: 8%;
  display: inline-block;
  width: 230px;
  font-weight: 700;
  line-height: 20px;
  padding-bottom: 7px;
  cursor: pointer;
}
.custom-tab.active {
  color: $color;
  border-bottom: 2px solid $color;
}
.custom_tab_item {
  min-height: 270px;
}

.cti-title {
  margin: 0 0 0.7em;
  font-size: 26px;
  font-weight: 700;
}
.cti-desc p span {
  display: block;
  color: $color;
  font-weight: 500;
}

.attention-section {
  padding: 65px 0;
  background: $color;
  color: #fff;
}

.university_title_box {
  margin: 0 0 1.5em;
}

.first_table_col {
  // padding-right: 4%;
  color: $color;
  font-weight: 700;
  // width: 27%;
}
.about-un-desc-table tr td:last-child {
  // width: 69%;
}
.about-un-desc-table tr td {
  padding-bottom: 1.2em;
  font-size: 18px;
  vertical-align: top;
}

.about-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.university-new-faculty {
  padding: 3em 0 5em;
  background: $color;
  color: #fff;
}

.pf-list {
  margin: 0 0 2.5em;
}

.pf-item {
  margin: 0 0 1.8em;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  @media screen and (min-width: 850px) {
    flex-direction: row;
  }
  background: #fff;
  color: #000;
  border-radius: 4px;
  transition: 0.3s;
}
.pf-item .desc-box {
  // width: 74.7%;
  flex: 1;
  padding: 30px 25px;
}
.pf-item-title {
  color: #fff;
  @media screen and (min-width: 850px) {
    max-width: 380px;
  }
  width: 100%;
  // height: 100%;
  // margin: 0 0 0.9em;
  font-size: 20px;
  font-weight: 700;
  // color: #151e33;
  min-height: 200px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-position: top;
  background-size: cover;
}
.pf-item-desc {
  margin: 0 0 1.4em;
}
.pf-item .pf-item-check-btn a {
  padding: 6px 15px;
  display: inline-block;
  text-align: center;
  background: $color;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  &:hover {
    opacity: 0.9;
  }
}

.university_title.center {
  text-align: center;
}
.un-privs {
  padding: 4.5em 0;
}

.un-privs .un-privs-box {
  margin: 3em 0 0;
}

.un-privs-flex {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.un-privs-item i {
  display: inline-block;
  margin: 0 0 1em;
}
.un-privs-title {
  margin: 0 0 1em;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  max-width: 210px;
}
.un-privs-item {
  margin: 0 0 1em;
  width: 22%;
  min-width: 280px;
}

.un-see-video {
  padding: 3em 0;
  margin: 0 0 4em;
}

.un-see-video .sv-title {
  margin: 1em 0 0.5em;
  font-size: 2.5rem;
  @media screen and (max-width: 550px) {
    font-size: 1.5rem;
  }
  font-weight: 700;
  line-height: 1;
}
.sv-desc {
  font-size: 18px;
}

.top-form {
  position: relative;
  padding: 30px;
  margin-top: 30px;
  text-align: center;
}
.top-form::before,
.top-form::after {
  left: 0;
  right: 0;
  height: 10px;
}
.top-form form::before,
.top-form form::after {
  top: 0;
  bottom: 0;
  width: 10px;
}
.top-form form::after,
.top-form form::before {
  content: "";
  clear: both;
  display: block;
  position: absolute;
}
.top-form::after,
.top-form::before {
  content: "";
  clear: both;
  display: block;
  position: absolute;
}
.banners li::after,
.top-form::after,
.parents li::after,
.language .inner::before,
#content ul.posts li .border::before,
.vuz-learn li::after {
  bottom: 0;
  background: -webkit-gradient(linear, left top, right top, from(#5b9bd9), to(#8881d1));
  background: -webkit-linear-gradient(left, #5b9bd9, #8881d1);
  background: -moz-linear-gradient(left, #5b9bd9, #8881d1);
  background: -o-linear-gradient(left, #5b9bd9, #8881d1);
  background: linear-gradient(to right, #5b9bd9, #8881d1);
}
main #content p.title {
  line-height: 30px;
}
#content p {
  line-height: 22px;
  margin-bottom: 15px;
}

.top-form .title {
  left: 0;
  right: 0;
  top: -15px;
  font-size: 32px;
  font-weight: 600;
  position: absolute;
}
.top-form p {
  font-weight: bold;
  margin-bottom: 20px;
}
main .title {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.university_video {
  background-color: $color;
}

.university_subtitle {
  font-size: 24px;
  font-weight: 600;
  color: $color2;
}
.university_title {
  // color: #fff;
}

.img-box {
  img {
    width: 100%;
  }
}

.about-img, .img-box__div, .img-box {
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.card-img-my {
  height: 200px;
  object-fit: cover;
}

.priem-url {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.website-url {
  &:hover {
    text-decoration: underline;
  }
}


.tooltip2 {
}

/* Tooltip text */
.tooltip2 .tooltiptext {
  visibility: hidden;
  // width: 120px;
  background-color: #fff;
  color: #000;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  font-size: 12px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: -50px;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip2:hover .tooltiptext {
  visibility: visible;
}

.tooltiptext:after {
  content: ' ';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
      position: absolute;
  bottom: -4px;
  left: 45%;
}

.div-center {
  margin: 0 auto;
}

.text-justify {
  text-align: justify;
}