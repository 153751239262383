.faculty_8 {
  background-image: url("../images/01_bio.jpg");
}
.faculty_32 {
  background-image: url("../images/04_ffk.jpg");
}
.faculty_24 {
  background-image: url("../images/08_pedfak.jpg");
}
.faculty_33 {
  background-image: url("../images/11_physt.jpg");
}
.faculty_27 {
  background-image: url("../images/14_geo.jpg");
}
.faculty_35 {
  background-image: url("../images/02_chemistry.jpg");
}
.faculty_28 {
  background-image: url("../images/05_rgf.jpg");
}
.faculty_37 {
  background-image: url("../images/09_law.jpg");
}
.faculty_29 {
  background-image: url("../images/12_pmk.jpg");
}
.faculty_30 {
  background-image: url("../images/03_psychology.jpg");
}
.faculty_25 {
  background-image: url("../images/06_history.jpg");
}
.faculty_26 {
  background-image: url("../images/10_math.jpg");
}
.faculty_34 {
  background-image: url("../images/13_filologia.jpg");
}
.faculty_44 {
  background-image: url("../images/07_eco.jpg");
}
